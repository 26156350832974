import { Button, Form, Table, TablePaginationConfig, Tooltip, Typography } from 'antd/lib'
import { SorterResult } from 'antd/lib/table/interface'
import Link from 'antd/lib/typography/Link'
import Title from 'antd/lib/typography/Title'
import {
  DeployActionEnum,
  DeployStatusEnum,
  DeviceStatusEnum,
  DevicesSortOptions,
  SoftwareAvailableDTO,
  SoftwareNameEnum,
  SortDirectionOptions,
  DeviceDTO,
  CameraDetailsDTO,
} from 'api/api_code'
import { DeviceDetailsEndpoints, SoftwaresEndpoints } from 'api/axios/axiosAPIGroups'
import clsx from 'clsx'
import { Icon } from 'components/UI/Icon/Icon'
import { IconNames } from 'components/UI/Icon/icons'
import { ConfirmationModal } from 'components/UI/Modals/ConfirmationModal'
import { IconType, NotificationModal } from 'components/UI/Modals/NotificationModal'
import { PAIRING_STEPS, pairingSteps } from 'core/pairing/pairing'
import { getFromSessionStorage, setToSessionStorage } from 'helpers/storageUtils'
import { useHTTPRequestHandler } from 'hooks/useHTTPRequestHandler'
import { usePairingContext } from 'hooks/usePairingContext'
import { useSession } from 'hooks/useSession'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import getFirmwareStatus from '../helpers/getFirmwareStatus'
import useCameraFirmwareActions, { FirmwareAction } from '../hooks/useCameraFirmwareStatus'
import { usePairing } from '../pairing/components/deploy/hooks/usePairing'
import styles from './CameraList.module.css'
import { CameraStatus } from './CameraStatus'
import FirmwareField, { FirmwareStatus } from './FirmwareField'
import { useCameraStore } from 'stores/camera.store'
import { useLoading } from 'hooks/useLoading'
import { InputSearch } from '@atvlibs/atvdesignsystem'
import { useSearchParams } from 'next/navigation'
import { useDebouncedCallback } from 'use-debounce'
import CameraListFooter from './CameraListFooter'
import Image from 'next/image'
import { getCameraLicense } from '../../helpers/camera'

const { Text } = Typography

function CamerasList() {
  const [openModal, setOpenModal] = useState<{ open: boolean; type: 'multi' | 'single' }>({
    open: false,
    type: 'single',
  })
  const [data, setData] = useState<Record<number, DeviceDTO[]>>({})
  const [total, setTotal] = useState(0)
  const [softwareAvailable, setSoftwareAvailable] = useState<SoftwareAvailableDTO>({
    name: '',
    version: { version_number: '' },
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<Record<number, string[]>>({ 1: [] })
  const [selectionCount, setSelectionCount] = useState(0)
  const [upgradeDevices, setUpgradeDevices] = useState([])
  const {
    currentStep,
    setCurrentStep,
    setPairingModalState,
    pairedDeviceUUID,
    softwareInstallationState,
    setOpenLicensingModal,
  } = usePairingContext()
  const { requestHandler } = useHTTPRequestHandler()
  const { deploySoftwareInstallation } = usePairing()
  const { firmwareActionInProgress, cameraFirmwareAction, resetFirmwareActions } =
    useCameraFirmwareActions()
  const { closeWizardSession } = useSession()
  const { setLoading } = useLoading()
  const { t } = useTranslation('cameras')
  const router = useRouter()
  const searchParams = useSearchParams()
  const params = new URLSearchParams(searchParams.toString())

  const paginationValues = useRef({
    search: params.get('search') || '',
    sortBy: (router.query['sortBy'] as string) || 'name',
    sortDir: (router.query['sortDir'] as string) || 'asc',
    pageSize: +router.query['pageSize'] || 10,
    currentPage: +router.query['page'] || 1,
  })

  const device_uuid = getFromSessionStorage('device_uuid')

  const cameraListCall = (onOkCallback: CallableFunction, autoLoading = true) => {
    requestHandler({
      requestPromise: DeviceDetailsEndpoints.getCamerasListDevicesGet(
        paginationValues.current.search,
        paginationValues.current.sortBy as DevicesSortOptions,
        paginationValues.current.sortDir as SortDirectionOptions,
        paginationValues.current.currentPage,
        paginationValues.current.pageSize
      ),
      onOkCallback,
      autoLoading,
    })
  }
  const onSearch = useDebouncedCallback((value) => {
    if (!value.target.value) {
      params.delete('search')
      paginationValues.current.search = ''
    } else {
      params.set('search', value.target.value)
      paginationValues.current.search = value.target.value
    }
    router.push({
      pathname: '/',
      query: {
        search: value.target.value,
        pageSize: paginationValues.current.pageSize,
        sortBy: paginationValues.current.sortBy,
        sortDir: paginationValues.current.sortDir,
      },
    })
  }, 300)

  useEffect(() => {
    const device_uuid = getFromSessionStorage('device_uuid')
    const session_token = getFromSessionStorage('session_token')
    if (session_token !== null && device_uuid !== null) {
      closeWizardSession(device_uuid)
    }
    sessionStorage.removeItem('device_uuid')
    sessionStorage.removeItem('camera-store')
  }, [])

  const accessToCamDetails = useCallback(
    (device_uuid) => {
      requestHandler({
        requestPromise: DeviceDetailsEndpoints.getCameraDetailDevicesDeviceUuidGet(
          device_uuid as string
        ),
        onOkCallback: (camera: CameraDetailsDTO) => {
          setLoading(false)
          useCameraStore.setState(camera)
        },
        autoLoading: false,
      })
    },
    [device_uuid]
  )

  useEffect(() => {
    if (!router.isReady) return
    cameraListCall((cameras) => {
      setData({ ...data, [paginationValues.current.currentPage]: cameras.items })
      setTotal(cameras.total)
    }, !params.get('search'))
    requestHandler({
      requestPromise: SoftwaresEndpoints.getSoftwareSoftwaresSoftwareGet(
        SoftwareNameEnum.Automatictv
      ),
      onOkCallback: (data) => {
        setSoftwareAvailable(data)
      },
      autoLoading: !params.get('search'),
    })
  }, [
    paginationValues.current.currentPage,
    paginationValues.current.search,
    paginationValues.current.sortBy,
    paginationValues.current.sortDir,
    router.isReady,
  ])

  const refreshCamList = (autoLoading = true) =>
    cameraListCall(
      (cameras) => {
        setData({ ...data, [paginationValues.current.currentPage]: cameras.items })
        setTotal(cameras.total)
        resetFirmwareActions()
      },
      params.get('search') ? false : autoLoading
    )

  useEffect(() => {
    const intervalTimer = setInterval(() => refreshCamList(false), 60 * 100)
    return () => {
      clearInterval(intervalTimer)
    }
  }, [
    paginationValues.current.search,
    paginationValues.current.pageSize,
    paginationValues.current.currentPage,
    paginationValues.current.sortDir,
    paginationValues.current.sortBy,
  ])

  useEffect(() => {
    paginationValues.current = {
      search: params.get('search') || '',
      sortBy: (router.query['sortBy'] as string) || 'name',
      sortDir: (router.query['sortDir'] as string) || 'asc',
      pageSize: +router.query['pageSize'] || 10,
      currentPage: +router.query['page'] || 1,
    }
  }, [router.isReady])

  useEffect(() => {
    localStorage.clear()
  }, [])

  const setTextStyles = (status: DeviceStatusEnum) =>
    clsx({
      [styles.soft]: status === DeviceStatusEnum.Offline,
      [styles.velvet]: status === DeviceStatusEnum.Recording,
    })

  const columns = useMemo(
    () => [
      {
        title: t('columnNames.name'),
        dataIndex: 'name',
        key: 'name',
        width: '22%',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, record) => (
          <div className={styles.nameCol}>
            <Text className={setTextStyles(record.status)}>{record.name}</Text>
            <Text style={{ color: 'var(--soft-500)', fontWeight: 400 }}>
              {record.producer?.serial_number}
            </Text>
          </div>
        ),
      },
      {
        title: t('columnNames.organization'),
        dataIndex: 'org_name',
        width: '20%',
        key: 'org_name',
        sorter: (a, b) => a.organization.name.localeCompare(b.organization.name),
        render: (_, record) => (
          <Text className={setTextStyles(record.status)} data-testid="camera_organization">
            {record.organization?.name}
          </Text>
        ),
      },
      {
        title: t('columnNames.firmware'),
        dataIndex: 'software_installed',
        key: 'firmware',
        width: '19%',
        render: (_, record) => {
          const { version, status } = getFirmwareStatus({ device: record, softwareAvailable })
          const firmwareAction = {
            [FirmwareStatus.UPGRADE]: (event) => {
              pairedDeviceUUID.current = record.uuid
              setOpenModal({ open: true, type: 'single' })
              event.stopPropagation()
            },
            [FirmwareStatus.INSTALL]: (event) => {
              cameraFirmwareAction[FirmwareStatus.INSTALL]({ cameraUUID: record.uuid })
              event.stopPropagation()
            },
          }
          const upgradeInProgress =
            firmwareActionInProgress[record.uuid] && FirmwareStatus.UPGRADING
          return (
            <FirmwareField
              version={version}
              newVersion={softwareAvailable?.version?.version_number}
              action={firmwareAction[status]}
              firmwareStatus={upgradeInProgress || status}
              deviceStatus={record.status}
              translate={t}
              disable={
                record.status === DeviceStatusEnum.Offline ||
                record.status === DeviceStatusEnum.Recording
              }
              actionInProgress={firmwareActionInProgress?.[record.uuid] ?? false}
            />
          )
        },
      },
      {
        title: t('columnNames.status'),
        dataIndex: 'status',
        width: '15%',
        key: 'status',
        render: (_, record) => (
          <CameraStatus camera={record} setOpenLicensingModal={setOpenLicensingModal} />
        ),
      },
      {
        title: t('columnNames.license'),
        key: 'license',
        width: '24%',
        dataIndex: 'license',
        sorter: (a, b) =>
          getCameraLicense(a.producer?.license_type).localeCompare(
            getCameraLicense(b.producer?.license_type)
          ),
        render: (_, record) => {
          const caseOffline = record.status === DeviceStatusEnum.Offline
          const caseRecording = record.status === DeviceStatusEnum.Recording
          const caseUpgrading =
            record.software_installing?.find(
              (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
            )?.action === DeployActionEnum.Upgrade
          const caseInstalling =
            record.software_installing?.find(
              (item) => item.version?.software?.name === SoftwareNameEnum.Automatictv
            )?.action === DeployActionEnum.Install
          const caseNotLicensed = record.status === DeviceStatusEnum.Unlicensed
          const disableCameraActions =
            caseOffline || caseUpgrading || caseInstalling || caseNotLicensed || caseRecording
          return (
            <div className={`${styles.settings} ${setTextStyles(record.status)}`}>
              {getCameraLicense(record.producer?.license_type)}
              <Tooltip
                placement="topRight"
                title={
                  caseOffline
                    ? t('tooltip.offline')
                    : caseUpgrading
                    ? t('tooltip.upgrading')
                    : caseInstalling
                    ? t('tooltip.installing')
                    : caseRecording
                    ? t('tooltip.recording')
                    : t('tooltip.camConfig')
                }
                className={styles['tooltipCameraList-config']}
              >
                <Button
                  type="link"
                  className={`${styles.hiddenButtons} ${
                    disableCameraActions ? styles.disabledSet : styles.available
                  }`}
                  {...(!disableCameraActions && {
                    onClick: (event) => {
                      useCameraStore.setState(record)
                      setToSessionStorage('lens-size', record.lens_size)
                      setToSessionStorage('license-type', record.producer.license_type)
                      event.stopPropagation()
                      router.push({
                        pathname: '/camera/[device_uuid]/settings',
                        query: { device_uuid: record.uuid },
                      })
                    },
                  })}
                >
                  <Icon component={IconNames.SETTINGS} size={24} className={styles.set}></Icon>
                </Button>
              </Tooltip>
            </div>
          )
        },
      },
    ],
    [firmwareActionInProgress, data, softwareAvailable]
  )

  const handleSelect = (record, selected) => {
    if (selected) {
      setSelectedRowKeys((keys) => ({
        ...keys,

        [paginationValues.current.currentPage]: [
          ...(keys[paginationValues.current.currentPage]?.length
            ? keys[paginationValues.current.currentPage]
            : []),
          record.uuid,
        ],
      }))
    } else {
      setSelectedRowKeys((keys) => {
        const index = keys[paginationValues.current.currentPage].indexOf(record.uuid)
        return {
          ...keys,
          [paginationValues.current.currentPage]: [
            ...keys[paginationValues.current.currentPage].slice(0, index),
            ...keys[paginationValues.current.currentPage].slice(index + 1),
          ],
        }
      })
    }
  }

  useEffect(() => {
    const canUpgradeDevices = []
    Object.values(selectedRowKeys)
      .flat()
      .forEach((device) => {
        let deviceData = undefined
        for (const deviceList of Object.values(data)) {
          const dev = deviceList.find((d) => d.uuid === device)
          if (dev) {
            deviceData = dev
            break
          }
        }
        if (!deviceData) return true
        const upgradeAvailable = getFirmwareStatus({ device: deviceData, softwareAvailable })
        deviceData?.status !== DeviceStatusEnum.Offline &&
          deviceData?.status !== DeviceStatusEnum.Recording &&
          upgradeAvailable.status === FirmwareStatus.UPGRADE &&
          canUpgradeDevices.push(device)
      })
    setUpgradeDevices(canUpgradeDevices)
    setSelectionCount(
      Object.values(selectedRowKeys).reduce((acc, selection) => {
        return acc + selection.length
      }, 0)
    )
  }, [Object.values(selectedRowKeys).flat().length])

  const rowSelection = {
    selectedRowKeys: selectedRowKeys[paginationValues.current.currentPage] ?? [],
    onSelect: handleSelect,
    columnTitle: ' ',
  }
  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) =>
      keys[paginationValues.current.currentPage]?.length ===
      data[paginationValues.current.currentPage].length
        ? { ...keys, [paginationValues.current.currentPage]: [] }
        : {
            ...keys,
            [paginationValues.current.currentPage]: data[paginationValues.current.currentPage].map(
              (r) => r.uuid
            ),
          }
    )
  }
  const handleUpgrade = (action) => {
    const upgradeAction = {
      single: () => {
        cameraFirmwareAction[FirmwareStatus.UPGRADE]({ cameraUUID: pairedDeviceUUID.current })
        setOpenModal({ open: false, type: 'single' })
      },
      multi: () => {
        setOpenModal({ open: false, type: 'multi' })
        Boolean(upgradeDevices.length) &&
          cameraFirmwareAction[FirmwareAction.BULK_UPGRADE](upgradeDevices)
        setSelectedRowKeys({ 1: [] })
        setUpgradeDevices([])
      },
    }
    upgradeAction[action]()
  }
  const isAllSelectedByPage =
    selectedRowKeys[paginationValues.current.currentPage]?.length ===
    data[paginationValues.current.currentPage]?.length

  useEffect(() => {
    setSelectionCount(
      Object.values(selectedRowKeys).reduce((acc, selection) => {
        return acc + selection.length
      }, 0)
    )
  }, [paginationValues.current.currentPage])
  return (
    <div className={styles.wrapperTable}>
      <div className={styles.header}>
        <Title level={3} className={styles.title}>
          {t('title')} <div className={styles.circle}>{total}</div>
        </Title>
        <div>
          <Form
            name="search-form"
            fields={[
              {
                name: ['cameraSearch'],
                value: paginationValues.current.search,
              },
            ]}
          >
            <Form.Item name="cameraSearch">
              <InputSearch placeholder={t('searchBarPlaceholder')} onChange={onSearch} />
            </Form.Item>
          </Form>
        </div>
        <Button
          type="primary"
          aria-label={t('pairButton')}
          onClick={() => {
            setPairingModalState(true)
          }}
        >
          <Icon component={IconNames.PLUS} size={24} />
          {t('pairButton')}
        </Button>
      </div>
      <NotificationModal
        titleIconType={
          softwareInstallationState === DeployStatusEnum.Succeed ? IconType.OK : IconType.KO
        }
        title={
          softwareInstallationState === DeployStatusEnum.Succeed
            ? t('installationModal.success.title')
            : t('installationModal.error.title')
        }
        body={
          <p>
            {softwareInstallationState === DeployStatusEnum.Succeed ? (
              t('installationModal.success.explanation')
            ) : (
              <Trans
                i18nKey="cameras:installationModal.error.explanation"
                components={[
                  <Link key="mail-support" href="mailto:support@automatic.tv">
                    {t('supportEmail')}
                  </Link>,
                ]}
              />
            )}
          </p>
        }
        open={currentStep?.index === PAIRING_STEPS.DEPLOY_STATUS}
        onOk={() => {
          softwareInstallationState === DeployStatusEnum.Succeed
            ? setCurrentStep((prevStep) => ({
                index: prevStep.index + 1,
                component: null,
                title: null,
                isCompleted() {
                  return true
                },
              }))
            : setCurrentStep(pairingSteps[1])
        }}
        width={438}
        centered
        footer={null}
        closable={true}
        onCancel={() => {
          setCurrentStep(pairingSteps[1])
        }}
        footerBtns={[
          <Button
            key={0}
            type="ghost"
            aria-label="Error"
            onClick={() => {
              softwareInstallationState === DeployStatusEnum.Succeed
                ? setCurrentStep((prevStep) => ({
                    index: prevStep.index + 1,
                    component: null,
                    title: null,
                    isCompleted() {
                      return true
                    },
                  }))
                : deploySoftwareInstallation()
            }}
          >
            {softwareInstallationState === DeployStatusEnum.Succeed
              ? t('installationModal.success.confirm')
              : t('installationModal.error.confirm')}
          </Button>,
        ]}
      />
      <Table
        className={styles.table}
        columns={columns}
        sortDirections={['ascend', 'descend']}
        onChange={(pagination: TablePaginationConfig, _, sorter) => {
          const sorterMod = sorter as SorterResult<any>
          const direction =
            sorterMod?.order === 'ascend' || sorterMod?.order === undefined ? 'asc' : 'desc'

          router.push({
            pathname: '/',
            query: {
              page: paginationValues.current.currentPage,
              pageSize: paginationValues.current.pageSize,
              ...(Object.keys(sorterMod).length && {
                sortBy: sorterMod?.field as string,
                sortDir: sorterMod?.field && direction,
              }),
            },
          })
        }}
        dataSource={data[paginationValues.current.currentPage]}
        rowClassName={styles.tableRow}
        rowKey={(record) => record.uuid}
        onRow={(record) => {
          return {
            onClick: () => {
              setToSessionStorage('device_uuid', record.uuid)
              accessToCamDetails(record.uuid)
              router.push({
                pathname: '/camera/[device_uuid]/details',
                query: { device_uuid: record.uuid },
              })
            },
          }
        }}
        rowSelection={{
          preserveSelectedRowKeys: true,
          ...rowSelection,
        }}
        pagination={{
          position: ['bottomCenter'],
          total: total,
          current: paginationValues.current.currentPage,
          pageSize: paginationValues.current.pageSize,
          onChange: (page, pageSize) =>
            (paginationValues.current = {
              ...paginationValues.current,
              currentPage: page,
              pageSize,
            }),
          showTotal: (total, range) => (
            <span
              style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '48px',
              }}
            >
              {t('pagination1')} {range[0]}-{range[1]} {t('pagination2')} {total}
            </span>
          ),
          showLessItems: true,
          showSizeChanger: false,
        }}
        data-testid="cameras-list"
        {...(total > 0 && {
          footer: () => (
            <CameraListFooter
              data={data[paginationValues.current.currentPage]}
              handleSelectAll={toggleSelectAll}
              handleSelectedKeys={setSelectedRowKeys}
              handleUpgrade={() => setOpenModal({ open: true, type: 'multi' })}
              selectedRowKeys={selectedRowKeys[paginationValues.current.currentPage]}
              checkAllSelected={isAllSelectedByPage}
              selectionCount={selectionCount}
              disableUpgrade={!upgradeDevices.length}
            />
          ),
        })}
      />

      <ConfirmationModal
        modalState={openModal.open}
        confirmTitleText={t('confirmationModal.title')}
        confirmQuestionText={
          openModal.type === 'single'
            ? t('confirmationModal.question')
            : t('bulkConfirmationModal.question', {
                count: upgradeDevices.length,
              })
        }
        explanationText={t('confirmationModal.explanation')}
        confirmGuideText={t('confirmationModal.confirmGuide')}
        cancelText={t('confirmationModal.cancel')}
        confirmText={t('confirmationModal.start')}
        closeCallback={() => {
          setOpenModal({ open: false, type: 'single' })
        }}
        confirmCallback={() => handleUpgrade(openModal.type)}
      />
      {total === 0 && (
        <div className={styles.noData}>
          <Image src={'/img/no-data.png'} alt="No cameras" width={217} height={178} />
          <p className={styles.noDataTitle}>{t('noData.title')}</p>
          <p>{t('noData.desc')}</p>
        </div>
      )}
    </div>
  )
}
export default CamerasList
